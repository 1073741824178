import backofficeService from '@/api/backoffice'

export default {
  /**
   * Get list of departments
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to departments
   */
  getAll(params) {
    return backofficeService.Api().get('department', { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Get specific department
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to department
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`department/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  
  /**
   * Create a department. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('department', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Update specific department
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`department/${id}`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Delete department
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`department/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },

}
