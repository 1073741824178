import backofficeService from '@/api/backoffice'

export default {
  /**
   * Get list of brands
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to brands
   */
  getAll(params) {
    return backofficeService.Api().get('brand', { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Get specific brand
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to brand
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`brand/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  
  /**
   * Create a brand. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('brand', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Update specific brand
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`brand/${id}`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Delete brand
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`brand/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },

}
