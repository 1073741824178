import {
  mapGetters
} from "vuex";

export default {
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  methods: {
    getDynamicPrice(product, array) {
      if (product.metadata && !product.metadata.standardPrice) {
        // const array = this.currentBusiness.metadata.dynamicPriceList
        const value = product.avgCost
        if (value > 0) {
          for (let i = 0; i < array.length; i++) {
            if (value >= array[i].from && value <= array[i].to) {
              let result = (value * array[i].markup / 100) + value
              let roundedValue = Math.round(result * 2) / 2;
              return roundedValue.toFixed(2);
            }
          }
        } else {
          return product.price; // Return null if no matching range is found
        }
      } else {
        return product.price;
      }
    },
    properCase(string) {
      if (string) {
        // Replace multiple spaces with a single space
        let newString = string.replace(/\s{2,}/g, ' ');

        // Split the string into words
        let sentence = newString.toLowerCase().split(" ");

        // Capitalize the first letter of each word
        for (let i = 0; i < sentence.length; i++) {
          // Check if the element is a string and has length
          if (typeof sentence[i] === 'string' && sentence[i].length > 0) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
          }
        }

        // Join the words back into a single string
        return sentence.join(" ");
      } else {
        // Return the original string if it's falsy (null, undefined, or empty)
        return string;
      }
    }
  },
}